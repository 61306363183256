import {inject } from 'aurelia-framework';
import {DashboardService} from '../../shared/services/dashboardservice';
import {Router} from 'aurelia-router';
import {ProfileService} from '../../shared/services/profielservice';
import {JwtService} from '../../shared/services/jwtservice';
import {config} from '../../shared/services/config';
import {HeaderService} from '../../shared/services/headerservice';
import {DialogService} from 'aurelia-dialog';
import Cropper from 'cropperjs';
import {Uploadimagepopupcomponent} from '../uploadimagepopup/uploadimagepopupcomponent';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { HeaderLayout } from '../../shared/layouts/headerlayout';
import { activationStrategy } from 'aurelia-router';
import {LoginService} from "../../shared/services/loginservice";

@inject(Router,DashboardService,ProfileService,JwtService,HeaderService,DialogService,ValidationControllerFactory,HeaderLayout, Element,activationStrategy,LoginService)
export class MyprofileComponent {
    selectedDropdownItem = null;
    showDropdown = false;
    activeTab = 1;
    isLoading = false;
    imageUrl = '';
    isPopupOpen = false;
    isImageLoaded = false;
    fileInput = null;
    imageRef = null;
    initialized=false;
    cropper = null;
    cropperContainer = null;
    Successmessages = false;
    constructor(Router,DashboardService,ProfileService,JwtService,headerservice,dialogService,controllerFactory,HeaderLayout,activationStrategy,loginService){
        this.router = Router;
        this.profileService=ProfileService;
        this.jwtService = JwtService;
        this.headerservice = headerservice;
        this.dialogService = dialogService;
        this.loginService = loginService;
        window.customWidth = window.innerWidth;
        this.controller = controllerFactory.createForCurrentScope();
        this.empid = this.jwtService.getEmployeeId();
        window.addEventListener('resize', ()=>{this.handleResize()});
        document.addEventListener('DOMContentLoaded', handleResize1);
        async function handleResize1() {
            await handleResize();
        }
        this.HeaderLayout = HeaderLayout;
        this.activationStrategy = activationStrategy;
    }
    async attached(params,navigationInstruction){
        // if(this.jwtService.getToken() === undefined){
        //     this.loginService.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.handleResize();
        const id = this.router.currentInstruction.queryParams.id;
        const queryParams = this.router.currentInstruction.queryParams.tab;
        queryParams ? this.handleTabClick(parseInt(queryParams)) : null;
        if(id != null && id !='' && id !=undefined)
        {
            this.empid=id;
        }
            const generalInformationData = await this.profileService.EmployeeDetails(this.empid)
            this.generalInformation = generalInformationData;
            document.addEventListener('click', this.handleClickOutside.bind(this));
                this.headerservice.dynamicHeader(this.empid)
                    .then(data => {
                        this.employeedetails = data;
                        this.userPhoto=config.img_url + data.PhotoPath;
                        this.designation = data.Designation;
                    });
        const liElement = document.querySelector('#dropdown');
        if(liElement.querySelector('.active')){
            const parentElement = liElement.querySelector('.active').parentNode;
            parentElement.classList.add('moved');
            const ul =  document.getElementById("mainTag");
            const swithcingElement =  ul.children[ul.children.length - 2];
            const getLiToReplaceSpan = document.getElementById("dropdown").querySelector('.moved');
            const switchingSpanElement = swithcingElement.querySelector('span');
            liElement.querySelector('.active').classList.add('py-2','px-3','mx-2','rounded-[9px]');
            swithcingElement.replaceChild(liElement.querySelector('.active'), switchingSpanElement);
            getLiToReplaceSpan.appendChild(switchingSpanElement);
        }
        liElement.addEventListener('click', () => {
            this.dropdownListElements = document.querySelectorAll('.nav');
            this.dropdownListElements.forEach(element => {
                element.classList.remove('active');
            });
            if(document.getElementById("dropdown").querySelector('.moved')){
                const previousLi =  document.getElementById("dropdown").querySelector('.moved')
                previousLi.classList.remove('moved')
            }
            const ul =  document.getElementById("mainTag");
            const swithcingElement =  ul.children[ul.children.length - 2];
            const switchingSpanElement = swithcingElement.querySelector('span');
            switchingSpanElement.classList.remove('text-white','py-2','px-3','mx-2','rounded-[9px]',);
            const clickedLi =  event.target.closest('span') ? event.target.closest('span') : event.target.closest('li').querySelector('span');
            clickedLi.classList.add('py-2','px-3','mx-2','rounded-[9px]');
            clickedLi ? clickedLi.parentNode.classList.add('moved') : event.target.closest('li').classList.add('moved');
            if (clickedLi) {
                const tabTrigger = clickedLi.getAttribute('data-tab-trigger');
                if (tabTrigger) {
                    this.handleTabClick(parseInt(tabTrigger));
                }
            }
            const getLiToReplaceSpan = document.getElementById("dropdown").querySelector('.moved');
            swithcingElement.replaceChild(clickedLi, switchingSpanElement);
            getLiToReplaceSpan?.appendChild(switchingSpanElement);
        });
        document.addEventListener('refreshImage', this.RefreshProfileImage);
    }
    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    async handleResize() {
        if (!this.initialized) {
            this.currentResolution = window.customWidth;
            this.initialized = true;
        } else {
            this.currentResolution = window.innerWidth;
        }
        const isMobile = this.isMobileDevice();
        const tabs = [
            { resolution: 1550, tab: '#tab-10' },
            { resolution: 1300, tab: '#tab-9' },
            { resolution: 1200, tab: '#tab-7' },
            { resolution: 1100, tab: '#tab-6' },
            { resolution: 750, tab: '#tab-5' },
            { resolution: 620, tab: '#tab-4' },
            { resolution: 460, tab: '#tab-3' }
        ];
        const dropdown = document.getElementById("dropdown");
        const mainTag = document.getElementById("mainTag");
        const lastLi = mainTag.querySelector('#tab-8');
        for (const { resolution, tab } of tabs) {
            const switchingLiElement = mainTag.querySelector(tab);
            const isInDropdown = dropdown.querySelector(tab);
            if ((window.innerWidth < resolution && switchingLiElement !== null) || (switchingLiElement!== null && this.currentResolution < resolution && !isInDropdown)) {
                switchingLiElement && switchingLiElement.classList.remove('text-white', 'whitespace-nowrap', 'text-center', 'text-xs');
                switchingLiElement && switchingLiElement.querySelector('span').classList.remove('py-2', 'px-3', 'mx-2', 'rounded-[9px]');
                switchingLiElement && switchingLiElement.classList.add('transition', 'hover:bg-zinc-200', 'dropdown-list', 'py-1.5', 'px-4');
                dropdown.append(switchingLiElement);
            } else if (( this.currentResolution >= resolution && isInDropdown) || (window.innerWidth >= resolution && isInDropdown)) {
                const switchingElement = dropdown.querySelector(tab);
                switchingElement.classList.add('text-white', 'whitespace-nowrap', 'text-center', 'text-xs');
                switchingElement.querySelector('span').classList.add('py-2', 'px-3', 'mx-2', 'rounded-[9px]');
                switchingElement.classList.remove('transition', 'hover:bg-zinc-200', 'dropdown-list', 'py-1.5', 'px-4');
                mainTag.insertBefore(switchingElement, lastLi);
            }
        }
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }
    async handleTabClick(tabNumber) {
        this.dropdownListElements = await document.querySelectorAll('.nav');
        this.dropdownListElements.forEach(element => {
            element.classList.remove('active');
        });
        this.activeTab = tabNumber;
        event?.target?.classList.add('active')
        this.router.navigateToRoute('myprofile', { tab: tabNumber });
    }
    handleFileSelect(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            this.imageUrl = e.target.result;
            this.isImageLoaded = true;
        };
        reader.readAsDataURL(file);
    }
    onImageLoad() {
        this.fileInput.style.display = 'none';
        this.imageRef.style.display = 'block';
        this.cropper = new Cropper(this.imageRef, {
            aspectRatio: 1,
            viewMode: 3
        });
        this.cropperContainer = this.cropper.getContainerData().element;
        this.cropperContainer.parentElement.style.display = 'block';
    }
    cancelCrop() {
        this.isImageLoaded = false;
        this.fileInput.value = null;
        this.imageRef.style.display = 'none';
        this.cropperContainer.parentElement.style.display = 'none';
        this.cropper.destroy();
        this.cropper = null;
    }
    cropImage() {
        const canvas = this.cropper.getCroppedCanvas({
            width: 200,
            height: 200
        });
        canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.append('image', blob, 'image.png');
            this.closePopup();
        });
    }
    handleClickOutside(event) {
        const dropdown = document.getElementById('dropdown');
        const toggle = document.getElementById('tab-8');
        if (dropdown && !dropdown.contains(event.target) && !toggle.contains(event.target)) {
            this.showDropdown = false;
        }
    }
    showModal = false;
    displayPopup(user) {
        this.dialogService.open({viewModel: Uploadimagepopupcomponent, model: user});
    }
    RefreshProfileImage = () => {
        this.headerservice.dynamicHeader(this.empid)
            .then(data => {
                this.userPhoto=config.img_url + data.PhotoPath;
                this.HeaderLayout.RefreshProfileImage(this.userPhoto);
                this.messages="Image uploaded successfully";
                this.Successmessages = true;
                setTimeout(()=>{this.messages=null},3000);
            });
    };
    cancel() {
        this.dialogController.cancel();
    }
    ok() {
        this.dialogController.ok();
    }
    detached() {
        window.removeEventListener('resize', this.handleResize());
        document.removeEventListener('change', this.handleClickOutside.bind(this));
    }
}
